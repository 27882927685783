/*** version 7 css ***/
.new-meet-sec .fild-row .open-list .selected a {
  background: url(../../images/select-arrow-open.svg) no-repeat 96%;
  color: #38417c;
}
body {
  background: #fff;
}
.meet-main {
  background: #eff1f9;
  display: table;
  height: 100%;
  width: 100%;
}
.menu_wrapper {
  background: #fff;
}
.new-meet-sec .meet-form .form-control {
  color: #73799f;
  font-size: 19px;
  font-weight: bold !important;
  font-family: "ProximaSoft-Regular";
}
.form-label-group input:not(:placeholder-shown) {
  color: #38417c !important;
  font-size: 18px !important;
}
.new-meet-sec.meet-form-main {
  padding-top: 165px;
  height: calc(100% - 90px);
}
.new-meet-sec .desktop-call-options {
  position: static;
  margin: 20px 0 0 0;
}
.new-meet-sec .test-sound-preview {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.25px;
  color: rgba(56, 65, 124, 0.5);
  background: #eff1f9;
  border-radius: 8px;
  padding: 4px 5px 0px;
  max-width: 320px;
  margin: 10px auto 0;
}
.video-unmute,
.video-mute,
.speaker-mute {
  height: 47px;
  width: 47px;
  background-size: 100%;
}
.speaker-unmute {
  height: 67px;
  width: 67px;
  background-size: 100%;
  top: -7px;
  left: -10px;
}
.new-meet-sec .meet-form h3 {
  margin-bottom: 30px;
}
a.admin-code-btn {
  background: #73799f;
  box-shadow: 0px 2px 14px rgba(39, 40, 42, 0.17);
  font-size: 14px;
  height: auto;
  padding: 7px 10px;
}
.meet-main-activated {
  background: #f9faff;
  height: 100%;
  display: table;
  width: 100%;
}
.video-bg.meet-video-bg {
  height: calc(100% - 90px);
  background-position: center;
}
.meet-joining-call .joining-calls-main,
.meet-joining-call.request-submitted {
  height: calc(100% - 90px);
}
.meet-joining-call .call-img {
  height: 100%;
}
.call-info-tabs .nav-tabs .nav-link {
  color: rgba(52, 54, 66, 0.4);
  border: 0;
}
.call-info-tabs .nav-tabs .nav-item.show .nav-link,
.call-info-tabs .nav-tabs .nav-link.active {
  border: 0;
}
.room-count {
  background: #f2f2f2;
  border-radius: 3px;
}
/* .meet-call-right{
    width: 40px;
    width: 388px;
    position: fixed;
    top: 0;
    right: 0;
} */
.meet-call-left {
  width: 100% !important;
  padding-right: 40px;
}
.meet-call-right {
  width: 40px !important;
  height: calc(100vh - 90px);
  position: fixed;
  top: 90px;
  right: 0;
  background: #fff;
  transition: 0.4s;
  z-index: 9999999;
}
.call-right.meet-call-right.open-info {
  width: 388px !important;
}
.openinfo-main {
  width: 40px;
  height: 100vh;
  position: absolute;
  background: #fff;
  top: 0;
  left: auto;
  border: 1px solid #f2f2f2;
  text-align: center;
  padding-left: 5px;
  border-bottom: 0;
  border-top: 0;
}
.call-right.meet-call-right.open-info .openinfo-main {
  right: 0;
}
.room-count {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #343642;
  font-family: "EuclidFlex-Medium";
  background: #f2f2f2;
  border-radius: 3px;
  text-align: center;
  padding: 4px 3px;
  width: 28px;
  font-weight: bold;
  display: block;
}
.msg-count {
  background: #f2f2f2;
  border-radius: 3px;
  text-align: center;
  padding: 7px 3px;
  width: 28px;
  display: block;
}
.msg-count > span {
  position: relative;
}
.msg-count > span:after {
  position: absolute;
  right: -1px;
  top: 1px;
  background: #ff7259;
  border-radius: 20px;
  height: 5px;
  width: 5px;
  content: "";
}
.room-chat-info li {
  margin-bottom: 10px;
}
a.clickto-open {
  height: 20px;
  width: 20px;
  z-index: 9999;
  position: relative;
  display: block;
  margin: 25px 0 25px 2px;
  color: #343642;
  font-size: 19px;
  font-weight: normal;
}
.call-right.meet-call-right.open-info .room-chat-info {
  display: none;
}
.call-right.meet-call-right.open-info .fa-angle-right {
  display: block;
  margin-left: 6px;
}
.call-right.meet-call-right .fa-angle-right {
  display: none;
}
.call-right.meet-call-right.d-flex.open-info .fa-angle-left {
  display: none;
}
.call-right.meet-call-right .call-info-tabs .nav-tabs .nav-item {
  width: auto;
  text-align: left;
  margin-left: 18px;
}
.call-right.meet-call-right .call-info-tabs .nav-tabs {
  justify-content: left;
  border-bottom: 2px solid #f2f2f2;
}
.call-right.meet-call-right .request-submitted {
  height: calc(100% - 90px);
}
.call-right.meet-call-right .call-info-tabs .tab-content {
  padding: 0 40px 10px 0;
}
.call-right.meet-call-right .call-info-tabs {
  width: 100%;
}
.room-view-opt-main .room-views {
  width: 100%;
  font-size: 14px;
  color: #343642;
  font-weight: bold;
  padding: 6px 15px;
  border-top: 1px solid #f2f2f2;
  background: #fff;
}
.room-view-opt-main .room-views a span {
  margin-left: 5px;
}
.room-view-opt-main .room-views a:focus,
.room-view-opt-main .room-views a:hover {
  color: #343642;
}
.room-view-opt-main .room-views.selected-open-view {
  background: rgba(242, 242, 242, 0.5);
  border-radius: 8px;
  border: 0;
}
.room-view-opt-main .room-views.selected-open-view a {
  background: url(../../images/arrowups.svg) no-repeat 100% center;
}
.open-view-options.room-view-opt-main .room-views.selected-open-view a {
  background: url(../../images/arrowdown.svg) no-repeat 100% center;
}
.open-view-options.room-view-opt-main {
  background: #f2f2f2;
  border-radius: 8px;
  /* overflow: hidden; */
  position: relative;
  margin: 20px 15px 0;
}
.room-view-opt-main {
  margin: 20px 15px 0;
}
.room-view-opt-main .room-views a {
  padding: 8px 0;
  display: block;
  position: relative;
  color: #343642;
}
.room-view-opt-main .room-views.selected-open-view a:before {
  width: 1.25px;
  background: #e0e0e0;
  position: absolute;
  right: 22px;
  top: 0;
  height: 100%;
  content: "";
}
.room-view-opt-main.open-view-options .room-views.selected-open-view {
  display: block;
  position: static;
}
.room-view-opt-main.open-view-options .room-views,
.room-view-opt-main.open-view-options .otherviews {
  display: block;
}
.room-view-opt-main .room-views,
.otherviews {
  display: none;
}
.room-view-opt-main .selected-open-view.room-views {
  display: block;
}
.incall-main-info > h2 {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #4f4f4f;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 15px;
  margin: 20px 0 0 0;
}
.incall-infomain {
  padding: 10px 15px 15px 15px;
}
.incall-bx {
  border-radius: 8px;
  background: rgba(242, 242, 242, 0.5);
  padding: 14px;
  margin-top: 10px;
}
.user-cll-img {
  float: left;
  width: 27px;
  height: 27px;
  overflow: hidden;
  border-radius: 100%;
}
.user-cll-info {
  display: table;
  padding-left: 15px;
}
.user-cll-info h5 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
  color: rgba(25, 35, 68, 0.6);
  padding: 0;
}
.user-cll-info p {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #bfc2d6;
  margin: 0;
}
.incall-bx.selected-bx {
  border: 1.5px solid #bfc2d6;
}
.calloptions li {
  display: inline;
  margin-left: 3px;
}
.meet-call-left .call-settings {
  right: 60px;
}
.close-info .meet-call-left .call-settings {
  right: 407px;
}
.close-info .call-left.meet-call-left {
  padding-right: 388px;
}
.otherviews {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -3px;
  border: 3px solid #f2f2f2;
}
.call-right.meet-call-right .user-chat-main {
  padding: 0 15px;
}
.call-right.meet-call-right .users-img {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.call-right.meet-call-right .user-chatting {
  display: table;
  padding-left: 10px;
  background: rgba(242, 242, 242, 0.5);
  border-radius: 8px;
  width: calc(100% - 38px);
  padding: 15px;
}
.call-right.meet-call-right .user-chatting h5 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: rgba(25, 35, 68, 0.6);
}
.call-right.meet-call-right .user-chatting p {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #343642;
  margin: 0;
}
.request-submitted-new,
.call-left.meet-call-left .call-img {
  height: calc(100vh - 90px);
}
.close-info .call-left.meet-call-left .user-call-info {
  padding-right: 388px;
}
.call-left.meet-call-left .user-call-info {
  padding-right: 40px;
  float: none;
}
.close-info .call-left.meet-call-left .call-options-main .user-call-infos {
  padding-right: 388px;
}
.call-left.meet-call-left .call-options-main .user-call-infos {
  padding-right: 40px;
}
.call-right.meet-call-right #chat {
  padding-top: 20px;
}
.call-right.meet-call-right .type-msg {
  width: calc(100% - 80px);
  opacity: 0;
}
.open-info.call-right.meet-call-right .type-msg {
  opacity: 1;
}
.selected-view-show {
  position: relative;
}
.selected-view-show:after {
  background: url(../../images/selected-view.svg) no-repeat right;
  position: absolute;
  right: 12px;
  top: 13px;
  width: 13px;
  height: 13px;
  content: "";
}
.grid-img-main {
  padding-bottom: 69%;
  background-repeat: no-repeat;
  /* position: relative; */
  background-size: cover;
  background-position: center;
}
.grid-info {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 20px;
  z-index: 999999;
  padding: 0 30px;
}
.grid-info h5 {
  font-family: "EuclidFlex-Medium";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.75);
  width: calc(100% - 70px);
}
.call-left.meet-call-left .grid-info .user-call-info {
  padding: 0 !important;
}
.gridview-call {
  padding: 0;
  position: relative;
  overflow: hidden;
}
.gridview-bg {
  background: #343642;
  overflow-y: scroll !important;
}
.call-options-main li {
  vertical-align: middle;
}
.grid-view-call-main .row {
  margin: 0;
}
.new-video-setting {
  max-width: 280px;
  margin: 0 auto;
  padding: 25px;
  background: #fff;
  max-width: 280px;
  margin: 0 auto;
  position: relative;
}
.new-video-setting .source.left-s {
  padding-right: 0;
}
.new-video-setting .source {
  width: 100%;
}
.new-video-setting.video-setting h2 {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.6px;
  color: #38417c;
  margin-bottom: 20px;
}
.new-video-setting .vol-info h4 {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.325px;
  color: rgba(56, 65, 124, 0.7);
}
.new-video-setting .source.right-s {
  padding-left: 0;
  margin-top: 15px;
}
.source select {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.45px;
  color: #38417c;
  background: rgba(200, 203, 224, 0.3) url(../../images/arw.svg) no-repeat 95%;
  border-radius: 5px;
  border: 0;
}
.new-video-setting .vol-info {
  margin: 0px 0 8px 0;
}
.new-video-setting .speaker-vol {
  width: 102px;
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
}
.new-video-setting .speaker-vol span {
  background: url(../../images/speaker.svg) no-repeat center left;
}
.new-video-setting .micro-vol {
  background-size: 33px;
  height: 22px;
  width: 34px;
}
.new-video-setting:after {
  top: 100%;
  right: 20px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.new-video-setting:after {
  border-color: transparent;
  border-top-color: #fff;
  border-width: 12px;
  margin-left: 0;
}
.new-video-setting .vol-range .vol {
  position: static;
}
.leave-call {
  max-width: 280px;
  margin: 20px auto 30px;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.leave-btn {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 13px !important;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.325px;
  color: #ffffff;
  height: 38px !important;
  display: block;
  width: 100%;
  padding: 10px;
}
.leave-call li {
  float: left;
  padding: 0 5px;
  width: 50%;
}
.leave-call li:first-child {
  margin-left: 0;
}
.leave-btn.border-btn {
  border: 1px solid #38417c;
  color: #38417c;
  height: 38px;
  border-radius: 8px;
}
.leave-btn.border-btn:hover,
.leave-btn.border-btn:focus {
  background: #38417c;
  color: #fff;
}
.leave-call > h2 {
  font-family: "ProximaSoft-Regular";
  font-weight: 500;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.6px;
  color: #38417c;
  margin: 0 0 15px 0;
}

.accept-area li {
  float: left;
}

.view-select {
  width: 90%;
  font-size: 14px;
  color: #343642;
  font-weight: bold;
  padding: 6px 15px;
  /* border-top: 1px solid #f2f2f2; */
  background: #fff;
}

.call-img {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.spotlight-view .call-left-video {
  /* width: 100%; */
  /* max-width: 100%; */
  /* min-height: 100%; */
  /* width: 100%; */
  height: auto;
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  transform: scale(1.1);
  height: 100%;
  left: 0%;
}

.sign-in {
  font-family: "EuclidFlex-Medium";
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.9px;
  color: #38417c;
}

.sign-in input::placeholder {
  font-family: "EuclidFlex-Medium";
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.9px;
  color: #38417c;
}
