@media (max-width: 1366px) and (min-width: 1200px){
    .video-bg.meet-video-bg .meet-form {
        margin-top: 0;
    }
    .new-meet-sec .meet-form h3 {
        margin-bottom: 10px;
    }
    .new-meet-sec.meet-form-main{
        padding-top:130px;
    }
    .video-bg.meet-video-bg .new-meet-sec.meet-form-main{
        padding-top:30px;
    }
    .meet-form .primary-btn.admin-code-btn {
        height: auto;
    }
}
@media screen and (max-width:1199px){
    .grid-info{
        padding: 0 20px;
    }
}
@media screen and (max-width:991px){
    .new-meet-sec.meet-form-main{
        padding-top: 145px;
    }
    .call-left.meet-call-left {
        padding-right: 0 !important;
        width: 100% !important;
    }
    .call-main.request-submitted.request-submitted-new {
        display: inline-block !important;
        width: 100%;
    }
    .call-right.meet-call-right.open-info {
        width: 100% !important;
        position: relative;
    }
    .call-right.meet-call-right{
        position: relative;
        width: 100% !important;
        display: inline-block !important;
        top: 0;
    }
    .close-info .call-left.meet-call-left .user-call-info {
        padding-right: 0;
    }
    .openinfo-main {
        position: absolute !important;
        height: 49px;
        padding: 0;
        top: 0px;
        right: 0;
        border-bottom: 1px solid #f2f2f2;
    }
    .room-chat-info {
        display: none;
    }
    a.clickto-open{
        margin:15px 8px;
    }
    .call-right.meet-call-right .call-info-tabs .tab-content {
        padding: 0 0 10px 0;
    }
    .close-info .meet-call-left .call-settings {
        right: 10px;
    }
    .call-right.meet-call-right .fa-angle-right {
        display: none !important;
    }
    a.clickto-open .fa-angle-left {
        transform: rotate(-90deg);
        display: block !important;
    }
    .call-right.meet-call-right .type-msg {
        width: calc(100% - 40px);
    }
    .open-info.call-right.meet-call-right .type-msg {
        opacity: 1 !important;
    }
    .call-right.meet-call-right .type-msg{
        opacity: 1 !important;
    }
    .meet-call-left .call-settings {
        right: 10px;
    }
}
@media screen and (max-device-height: 414px) and (orientation: landscape){
.new-meet-sec .meet-form {
    margin-top: 30px;
}
.new-meet-sec.meet-form-main {
    padding-top: 70px;
}
.video-bg.meet-video-bg{
    height:auto !important;
}
.meet-joining-call .joining-calls-main,.meet-joining-call.request-submitted{
    height:auto !important;
}
.close-info .call-left.meet-call-left .call-options-main .user-call-infos {
    padding-right:0;
}
.call-left.meet-call-left .call-options-main .user-call-infos {
    padding-right: 0;
}
}
@media screen and (max-width:767px){
    .video-unmute, .video-mute, .speaker-mute {
        height: 60px;
        width: 60px;
        background-size: 100%;
    }
    .speaker-unmute {
        height: 86px;
        width: 86px;
        background-size: 100%;
        top: -9px;
        left: -13px;
    }
    .new-meet-setting .setting-info{
        margin-bottom: 10px;
    }
    .new-meet-sec.meet-form-main {
        padding-top: 100px;
    }
    .new-meet-sec .meet-form {
        margin-top:0;
    }
    section.main-bg-sectoin.meet-form-main.new-meet-sec {
        height: auto !important;
    }
    .call-options-main.mobile-call-options{
        position: static;
        margin-top: 10px;
    }
    .meet-joining-call.request-submitted {
        height: auto;
    }
    .meet-joining-call .joining-calls-main{
        height:100% !important;
    }
    .meet-joining-call .call-img {
        height: 385px;
    }
    .video-bg.meet-video-bg{
        height:auto;
        padding-bottom: 55px;
        background: url(../../images/video-mobile-bg.jpg) no-repeat;
        background-position: center center;
    }
    .menu_wrapper{
        background: #fff;
        border-bottom:1px solid rgba(56, 65, 124, 0.2);
    }
    .call-left.meet-call-left .volume-icon > span {
        font-size: 19px;
        background: none;
    }
    .call-left.meet-call-left .call-options-main .volume-icon{
        top: -26px;
    }
    .call-left.meet-call-left .call-img {
        height: 317px;
    }
    .call-left.meet-call-left .user-call-info {
        margin-top: 0;
        position: absolute;
        bottom: 10px;
    }
    .call-left.meet-call-left .call-options-main{
        bottom: 0;
    }
    a.clickto-open {
        margin: 14px 10px;
    }
    .call-info-tabs .nav-tabs .nav-link span:before{
        content: none;
    }
    .call-right.meet-call-right .call-info-tabs .nav-tabs .nav-item {
        width: 50%;
        text-align: center;
        margin-left: 0;
    }
    .call-right.meet-call-right .call-info-tabs .nav-tabs {
        width: calc(100% - 40px);
    }
    .call-left.meet-call-left .user-call-info a {
        background-size: 25px;
        height: 25px;
        width: 25px;
        margin: 0;
    }
    .call-right.meet-call-right .type-msg {
        width: calc(100% - 30px);
        opacity: 1;
        margin-left: auto;
        margin-right: auto;
    }
    .call-left.meet-call-left .user-call-infos {
        float: right;
        margin-right: 15px;
    }
    .call-left.meet-call-left .call-options-main li{
        margin:0 2px;
    }
    .call-left.meet-call-left .speaker-unmute {
        height: 30px;
        width: 30px;
        background-size: 35px;
        top: -5px;
        left: -7px;
    }
    .call-left.meet-call-left .video-unmute, .call-left.meet-call-left .video-mute, .call-left.meet-call-left .speaker-mute {
        height: 23px;
        width: 23px;
        background-size: 23px;
    }
    .call-left.meet-call-left .video-m,  .call-left.meet-call-left .speaker {
        margin-top: -30px;
    }
    .call-right.meet-call-right  .call-info-tabs .nav-tabs .nav-link {
        color: #343642;
        border-bottom:1px solid #BFC2D6;
    }
    .call-right.meet-call-right  .call-info-tabs .nav-tabs .nav-link.active{
        border-color: transparent;
    }
    .call-right.meet-call-right .call-info-tabs .nav-tabs {
        border-bottom: 1px solid #F2F2F2;
    }
    .user-cll-info{
        padding-left: 3px;
    }
    .call-right.meet-call-right .mobile-calls .video-unmute,
    .call-right.meet-call-right .mobile-calls .video-mute,
     .call-right.meet-call-right .mobile-calls .speaker-mute,
      .call-right.meet-call-right .mobile-calls .video-unmute{
        width: 46px;
        height: 46px;
    }
    .call-right.meet-call-right .mobile-calls .speaker-unmute{
        height: 69px;
        width: 69px;
    }
    .call-right.meet-call-right .mobile-calls .setting{
        width: 71px;
        height: 71px;
        background-size: 100%;
    }
    .call-right.meet-call-right .mobile-calls .call-settings{
        /* margin-bottom: 13px; */
        margin-bottom: 98px;
    }
    .call-right.meet-call-right .mobile-calls .call-option-img {
        height: 50px;
        width: 50px;
        background-size: 100% !important;
    }
    .call-right.meet-call-right .mobile-calls {
        margin: 0;
        padding: 0;
    }
    .call-right.meet-call-right .mobile-calls .call-options-main{
        margin-top: 0;
    }
    .gridview-call {
        padding: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .call-left.meet-call-left .call-img.gridview-bg {
        height: 100%;
    }
    .call-left.meet-call-left .grid-info .user-call-info {
        position: static;
    }
    .call-left.meet-call-left .grid-info .user-call-info a.speaker-unmute {
        background-size: 36px;
        height: 36px;
        width: 36px;
        top: -4px;
        left: -6px;
    }
    .grid-info h5{
        width:calc(100% - -155px);
    }
    .grid-info {
        padding: 0 15px;
    }
    .grid-info .user-call-info ul li{
        display: inline-block;
        vertical-align: middle;
        margin: 5px 0 0 0;
    }
    .call-right.meet-call-right .custom-scrl-content {
        height: 360px;
    }
    .call-right.meet-call-right  .mCSB_container {
    padding: 0 10px 0 0 !important;
    }
    .call-right.meet-call-right .mCSB_scrollTools .mCSB_draggerRail {
        background-color: #BFC2D6 !important;
        width: 14px !important;
    }
    .call-right.meet-call-right .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #73799F !important;
        border-radius: 5px !important;
        width: 12px !important;
        height: 292px;
    }
    .new-video-setting:after{
        left: auto;
    }
}
@media screen and (max-width:413px){
    .meet-form{
        width: 292px;
    }
    .new-meet-sec .test-sound-preview{
        max-width: 292px;
    }
    .calloptions li{
        margin-left: 0;
    }
}